export const state = () => {
  return {
    title: 'การแจ้งเตือน',
    msg: '',
    isShow: false,
    loading: false,
    successDialog: false,
  }
}

export const mutations = {
  SET_TITLE(state, value) {
    state.title = value
  },
  SET_MSG(state, value) {
    state.msg = value
  },
  SET_IS_SHOW(state, value) {
    state.isShow = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_SUCCESS_DIALOG(state, value) {
    state.successDialog = value
  },
}
