import inside from '@turf/inside'
import supportProvince from '~/variables/supportProvince.json'
export default (ctx, inject) => {
  inject('helpers', {
    routeProvince($key) {
      if ($key) {
        return '/dashboard/' + ctx.route.params.province + '' + $key
      } else {
        return '/dashboard/' + ctx.route.params.province
      }
    },
    pushRouter($key) {
      setTimeout(() => ctx.redirect({ path: this.routeProvince($key) }), 100)
    },
    getProvinceIdByUrl() {
      if (ctx.route.params.province) {
        return parseInt(ctx.route.params.province)
      } else {
        const arrayPath = ctx.route.path.split('/')
        return parseInt(arrayPath[2])
      }
    },
    getProvinceName() {
      return supportProvince[this.getProvinceIdByUrl()].name
    },
    warningPermission() {
      return 'ขออภัยในความไม่สะดวก คุณไม่มีสิทธิ์ในหน้านี้ <br>กรุณาติดต่อผู้ดูแลระบบคุณ'
    },
    getImage(path) {
      return process.env.API_BASE_URL + '/' + path
    },
    validateLatLng(latitude, longitude) {
      const lat = latitude
      const lng = longitude
      if (isFinite(lat) && (Math.abs(lat) < -90 || Math.abs(lat) > 90)) {
        this.errorModal('ละติจูด ตัวเลขจะอยุ่ระหว่าง -90 ถึง 90 ')
        return false
      } else if (
        isFinite(lng) &&
        (Math.abs(lng) < -180 || Math.abs(lng) > 180)
      ) {
        this.errorModal('ลองจิจูด ตัวเลขจะอยุ่ระหว่าง -180 ถึง 180 ')
        return false
      } else if (!lat || !lng) {
        this.errorModal('กรุณากรอกข้อมูลให้ครอบทั้ง ละติจูด - ลองจิจูด')
        return false
      }
      return true
    },
    async verifyCoordinateInSubDistrict(
      latitude,
      longitude,
      polygonPath,
      districtName,
      success,
      error,
      caught
    ) {
      if (this.validateLatLng(latitude, longitude)) {
        // แก้ไขการตรวจสอบหมุด จากปกติให้อยู่ภายในตำบล เป็นอยู่นอกตำบลได้แต่ให้อยู่ภายในอำเภอ july 2023
        let checkInSideDistrict = 0
        await polygonPath.forEach((poly) => {
          if (districtName === poly.data.district.title.title_th) {
            const latlng = [longitude, latitude]
            const isInside = inside(latlng, poly.geometry)
            if (isInside) {
              checkInSideDistrict += 1
              success()
              // console.log('The point is within the polygon.')
            }
          }
        })
        if (checkInSideDistrict === 0) {
          error()
        }
        // console.log(polyTrue, subDistrictName)
      }
    },
    getCenterFromPolygonData(polygonArr) {
      const latlngPath = []
      let sumLat = 0
      let sumLng = 0
      let cnt = 0
      for (const k2 in polygonArr) {
        latlngPath.push({ lat: polygonArr[k2][1], lng: polygonArr[k2][0] })
        sumLat += polygonArr[k2][1]
        sumLng += polygonArr[k2][0]
        cnt++
      }

      const center = {
        lat: sumLat / cnt,
        lng: sumLng / cnt,
      }
      return { center, latlngPath }
    },
    getProvincePosition() {
      return supportProvince[this.getProvinceIdByUrl()].position
    },
  })
}
