
import error403 from '~/components/error/403.vue'
import error404 from '~/components/error/404.vue'
import error500 from '~/components/error/500.vue'
export default {
  name: 'NuxtError',
  components: {
    error403,
    error404,
    error500,
  },
  // layout: 'default', // optional
  layout(context) {
    if (context.error.statusCode === 500) {
      return 'empty'
    } else {
      return 'default'
    }
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 403) {
        return error403
      }
      if (this.error.statusCode === 404) {
        return error404
      }
      // catch everything else
      return error500
    },
  },
}
