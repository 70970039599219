const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['adminOrSuperAdmin'] = require('../middleware/adminOrSuperAdmin.js')
middleware['adminOrSuperAdmin'] = middleware['adminOrSuperAdmin'].default || middleware['adminOrSuperAdmin']

middleware['province'] = require('../middleware/province.js')
middleware['province'] = middleware['province'].default || middleware['province']

middleware['super-admin'] = require('../middleware/super-admin.js')
middleware['super-admin'] = middleware['super-admin'].default || middleware['super-admin']

middleware['superAdmin'] = require('../middleware/superAdmin.js')
middleware['superAdmin'] = middleware['superAdmin'].default || middleware['superAdmin']

export default middleware
