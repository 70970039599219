export default function ({store, route, error}) {
  if (store.state.auth.loggedIn) {
    let routeProvinceId = null
    if(route.params.province) {
      routeProvinceId = parseInt(route.params.province)
    } else {
      const arrayPath = route.path.split("/")
      routeProvinceId = parseInt(arrayPath[2])
    } 
    if(routeProvinceId) {
      // console.log('have province')
      let permis = false
      for (const province in store.state.auth.user.provinces) {
          // console.log(store.state.auth.user.provinces[province].id, parseInt(route.params.province))
          if(store.state.auth.user.provinces[province].id === routeProvinceId){
              permis = true
              break
          }
      }
      // console.log(permis)
      if (!permis) {
          // console.log('no permission province')
          return error({ statusCode: 403, message: "คุณไม่มีสิทธิในการเข้าถึง หรือจัดการข้อมูลในจังหวัดนี้" })
      }
    } else {
    //   // const arrayPath = route.path.split("/");
      console.log('no have province')
    }
  }
}