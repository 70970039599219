export const state = () => {
  return {
    promotion: '',
    image_path: '',
    price: null,
    promotion_price: null,
  }
}

export const mutations = {
  SET_PROMOTION(state, value) {
    state.promotion = value
  },
  SET_IMAGE_PATH(state, value) {
    state.image_path = value
  },
  SET_PRICE(state, value) {
    state.price = value
  },
  SET_PROMOTION_PRICE(state, value) {
    state.promotion_price = value
  },
}
