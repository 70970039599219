export const mutations = {}

export const getters = {
    isSuperAdmin (state) {
        if(state.auth.loggedIn){
            return state.auth.user.user_level === 99
        }
        return false
    },
    isAdmin (state) {
        if(state.auth.loggedIn){
            return state.auth.user.user_level === 2
        }
        return false
    },
    isMember (state) {
        if(state.auth.loggedIn){
            return state.auth.user.user_level === 1
        }
        return false
    },
}
