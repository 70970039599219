export default ({ store, route, $moment }, inject) => {
  inject('util', {
    numberFormat (n, f) {
      if (n === '') {
        return ''
      } else if (n === 0 || isNaN(n) || n === null || n === undefined) {
        return (f === undefined) ? '0' : parseFloat('0').toFixed(f)
      }

      if (f === undefined) {
        return (parseFloat(n) + '').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
      return parseFloat(n).toFixed(f).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    },
    numberFormatMega (n, f = 1) {
      if (n === '') {
        return ''
      } else if (n === 0 || isNaN(n) || n === null || n === undefined) {
        return (f === undefined) ? '0' : parseFloat('0').toFixed(f)
      } else if (n !== 0 && n < 1000000) {
        return parseFloat(n / 1000000).toFixed(f).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else if (n !== 0 && n >= 1000000 && n % 1000000 !== 0) {
        return parseFloat(n / 1000000).toFixed(f).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else if (n !== 0 && n >= 1000000 && n % 1000000 === 0) {
        return parseFloat(n / 1000000).toFixed(f).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      } else {
        return parseFloat(n / 1000000).toFixed(f).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
    },
  })
}